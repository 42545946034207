import React, { lazy, Suspense } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";


// Modules import with lazy loading
const Login = lazy(() => import("./prelogin-components/login"));
const SignUp = lazy(() => import("./prelogin-components/signup"));
const LandingPage = lazy(() => import("./prelogin-components/landing-page"));
const Home = lazy(() => import("./postlogin-components/home"));
const ContactUs = lazy(() => import("./prelogin-components/contactUs"));
const Blogs = lazy(() => import("./prelogin-components/blogs"));
const OurclientOnUser = lazy(() => import("./prelogin-components/ourClientOnUser"))
const OurTeamOnUser = lazy(() => import("./prelogin-components/ourteamOnUser"))
const LatestAdditionOnUser = lazy(() => import("./prelogin-components/latestAdditionOnUser"))
const RecentCampaignOnUser = lazy(() => import("./prelogin-components/recentCampaignOnUser"))
const MediaSpendOnUser = lazy(() => import("./prelogin-components/mediaSpendOnUser"))
const CarrierOnUser = lazy(() => import("./prelogin-components/carrierOnUser"))
const UserHistory = lazy(() => import("./prelogin-components/userHistory"))
const AboutUs = lazy(() => import("./prelogin-components/aboutUs"));
const PrivacyPolicy = lazy(() => import("./prelogin-components/privacyPolicy"));
const ResetPassword = lazy(() => import("./prelogin-components/resetPassword"));
const AdminHome = lazy(() => import("./postlogin-components/admin/adminHome"));

function App() {
  // const navigate = useNavigate();
  const phoneNumber = "+918824603418"; // Replace with your WhatsApp number (e.g., country code + number)
  const message = "Hello! I would like to know more about your services.";
  const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  // const enquiryNow = () => {
  //   navigate('/contactUs');
  // }

  return (
    <>
      <div className="App">
        <div className="fixed whatsAppIcon">
          <a href={whatsappURL} target="_blank" rel="noopener noreferrer">
            <img src="/image/whatsapp.png" alt="whatsApp" className="h-16 w-16"></img>
          </a>
        </div>
        <div className="fixed enquiry">
          <a href="/contactUs" target="_blank" className="h-16 w-16 p-2 flex justify-center items-center bg-red-700 rounded-full no-underline">
            <strong className="text-white text-center text-xs enquiryNow">ENQUIRY NOW</strong>
          </a>
        </div>
        <Suspense>
          <Router>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/contactUs" element={<ContactUs />} />
              <Route path="/dashboard/*" element={<Home />} />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/ourclientOnUser" element={<OurclientOnUser />} />
              <Route path="/ourTeamOnUser" element={<OurTeamOnUser />} />
              <Route path="/recentCampaignOnUser" element={<RecentCampaignOnUser />} />
              <Route path="/latestAdditionOnUser" element={<LatestAdditionOnUser />} />
              <Route path="/mediaSpendOnUser" element={<MediaSpendOnUser />} />
              <Route path="/carrierOnUser" element={<CarrierOnUser />} />
              <Route path="/userHistory" element={<UserHistory />} />
              <Route path="/aboutUs" element={<AboutUs />} />
              <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/adminDashboard/*" element={<AdminHome />} />
            </Routes>
          </Router>
        </Suspense>
      </div>
    </>
  );
}

export default App;
