import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartItems: []
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const existingItem = state.cartItems.find(
        (item) => item.spotId === action.payload.spotId
      );
      if (existingItem) {
        state.cartItems = state.cartItems.filter(
          (item) => item.spotId !== action.payload.spotId
        );
      } else {
        state.cartItems.push({ ...action.payload, count: 1 });
      }
    },

    clearCart: (state) => {
      state.cartItems = [];
    },
  },
});

export const { addToCart, removeFromCart, clearCart } = cartSlice.actions;
export default cartSlice.reducer;